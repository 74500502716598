function supportsFlexGap() {
  var box = document.createElement("div");
  box.style.display = "flex";
  box.style.flexDirection = "column";
  box.style.rowGap = "1px";

  box.appendChild(document.createElement("div"));
  box.appendChild(document.createElement("div"));

  document.body.appendChild(box);

  if (box.scrollHeight !== 1) {
    document.documentElement.classList.add("no-flex-gap");
  }

  box.parentNode.removeChild(box);
}

window.addEventListener("DOMContentLoaded", supportsFlexGap);
